import * as React from 'react';
import { Helmet } from 'react-helmet';
import ContactForm from '../components/ContactForm';
import Hero from '../components/Hero';
import GlobalStyles from '../styles/GlobalStyles';

const image = import('../images/cow+field_logo stacked_black_RGB.png');

const lang = 'en';

const WeddingCatering = () => (
        <>
                <Helmet
                        htmlAttributes={{ lang }}
                        title="Wedding Catering | Cow + Field"
                        meta={[
                                {
                                        name: 'description',
                                        content: 'Feasts and fires. With expertise in wedding catering, corporate events and private parties, we work with you to create the perfect menu for your event.',
                                },
                                {
                                        property: 'og:title',
                                        content: 'Wedding Catering | Cow + Field',
                                },
                                {
                                        property: 'og:description',
                                        content: 'Feasts and fires. With expertise in wedding catering, corporate events and private parties, we work with you to create the perfect menu for your event.',
                                },
                                {
                                        property: 'og:type',
                                        content: 'website',
                                },
                                {
                                        property: 'og:image',
                                        content: image,
                                },
                                {
                                        name: 'twitter:card',
                                        content: 'summary',
                                },
                                {
                                        name: 'twitter:creator',
                                        content: 'Cow + Field',
                                },
                                {
                                        name: 'twitter:title',
                                        content: 'Cow + Field',
                                },
                                {
                                        name: 'twitter:description',
                                        content: 'Feasts and fires. With expertise in wedding catering, corporate events and private parties, we work with you to create the perfect menu for your event.',
                                },
                        ]}
                />
                <main>
                        <Hero />
                </main>
                <GlobalStyles />
        </>
);

export default WeddingCatering;
